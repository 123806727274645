import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_home.css';
import logo from '../../assets/logo/logo_long.svg'
import Circles from '../../components/aesthetics/Circles';
import Section from '../../components/section/Section';
import { greetings } from '../../data/greetings';
import {lectors} from '../../data/lectors';
import ImageLink from '../../components/layouts/ImageLink';
import Slider from '../../components/slider/Slider';
import { Link } from 'react-router-dom';
import Navbar from '../../components/nav/Navbar';
import { CalendarMonth, LocationOn } from '@mui/icons-material';
import Counter from '../../components/counter/Counter';
import {isBrowser, isMobile } from 'react-device-detect';

export default function News(){


    return (
        <>
        <Navbar elements={[
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        {
            text: 'Novosti',
            link: '/novosti',
            key: 'novosti'
        },
        {
            text: 'Prijava',
            link: '/prijava',
            key: 'naslovna'
        },
        {
            text: 'Pregled događaja',
            link: '/pregled',
            key: 'pregled'
        },


    ]}/>
    <div className="dark-background flex-column fullheight">
    
    </div>
    </>
    )
}