import * as React from 'react';
import Footer from '../../components/footer/Footer';

import '../../assets/style.css';
import '../../assets/style_home.css';

import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';
import {isBrowser, isMobile } from 'react-device-detect';
import { FormDataBuilder, makeFormControls } from '../../components/formElements/util';
import zupanije from '../../assets/zupanije_arr';
import { api } from '../../environment';
import Form from '../../components/formElements/Form';
import WeekList from '../../components/layouts/WeekList';
import axios from 'axios';
export default function EventView(){

    let formDataBuilder = new FormDataBuilder();
    const [county, setCounty] = React.useState('-1');
    const [data, setData] = React.useState(null)
    

    const handleCountyChange = (e)=>{
        setCounty(e.target.value);
    }

    let newFormData = formDataBuilder
    .addSelectField('county', 'županija', false, zupanije, false, [], {flex:"1 1 45%"}, handleCountyChange)

    const [formControls, setFormControls] =React.useState(makeFormControls(newFormData.get()));

    const getData = () => {
        try{
        let call = fetch(api+"/get_week_activities/"+county.toString(),{
            method: "GET",
            })
            
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    const getDataPhuck = ()=>{
        try{
            let call = axios.get(api+"/get_week_activities/"+county.toString())
                
            return call
                } catch(err){
                    console.log(err);
                    return {}
                }
    }

    React.useEffect(()=>{
        if(county!='-1'){
        getDataPhuck().then((data)=>{
            console.log(data)
            setData(()=>{
                return data['data'].data
            });
        
    })
    }
}, [county])

    return (
        <>
        <Navbar elements={[
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        /*{
            text: 'Novosti',
            link: '/novosti',
            key: 'novosti'
        },*/
        {
            text: 'Prijava događanja',
            link: '/prijava',
            key: 'prijava'
        },
        {
            text: 'Pregled događaja',
            link: '/pregled',
            key: 'pregled'
        },

    ]}/>
    <div className="content">
    <Section title={<div style={{textAlign:"right"}} className="section-title-font">Pregled događanja</div>} 
    content={<div style={{textAlign:"center"}}>
            <div style={{width:"50%", textAlign:"center", margin:"auto"}}>
        <Form formData={newFormData.get()} formControls={formControls} setFormControls={setFormControls}/>
        </div>
        
        </div>}
    />
    
    <WeekList data={data}></WeekList>
    
    </div>
    </>
    )
}