const oblici = [
    {value:1, text:"Predavanje"},
    {value:2, text: "Radionica"},
    {value:3, text: "Okrugli stol"},
    {value:4, text: "Tribina"},
    {value:5, text: "Izložba"},
    {value:6, text:"Predstavljanje knjige/mjernog instrumenta"},
    {value:7, text:"Ostalo"}
]

export default oblici