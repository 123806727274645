import * as React from 'react';

import '../../assets/style.css';
import '../../assets/style_home.css';
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';
import { isMobile } from 'react-device-detect';

export default function Home(){


    return (
        <>
        <Navbar elements={[
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        /*{
            text: 'Novosti',
            link: '/novosti',
            key: 'novosti'
        },*/
        {
            text: 'Prijava događanja',
            link: '/prijava',
            key: 'prijava'
        },
        {
            text: 'Pregled događanja',
            link: '/pregled',
            key: 'pregled'
        },


    ]}/>
    <div className="content">
    <div className={isMobile?"white-background flex-column title background-mobile fullheight bac":"white-background flex-column title fullheight"}>
    
    </div>
    <Section title={<div style={{textAlign:"right"}} className="section-title-font">18. tjedan psihologije u Hrvatskoj</div>} content={
        <div>
            <p style={isMobile?{fontSize:"1.3rem", padding:"1rem"}:{fontSize:"2rem"}}>
            Poštovane kolegice, poštovani kolege,<br></br>

S osobitim zadovoljstvom najavljujemo 18. Tjedan psihologije u Hrvatskoj koji će se u organizaciji Hrvatskog psihološkog društva održati od 17. do 23. veljače 2025.g. Po sedamnaesti put imat ćemo prigodu da kroz organizaciju različitih događanja struku predstavimo zajednici i pošaljemo poruku kako život i pojednica i zajednice može biti bolji, ugodniji, kvalitetniji.<br/><br/>

Tjedan psihologije prvi put smo organizirali 2008. godine kad smo pozvali kolegice i kolege psihologe da se različitim aktivnostima uključe u obilježavanje 55. godina postojanja naše najstarije strukovne udruge psihologa. Odaziv je bio iznenađujuće dobar, veliki broj kolegica i kolega pokazao je entuzijazam i motivaciju da psihologiju približi i predstavi zajednici te da pokaže svu širinu uključenosti psihologijske teorije i prakse u život pojedinca i zajednice. Aktivnost smo nastavili i sljedećih godina pa smo tako u prošlom 17. tjednu imali uključenih preko 750 sudionika, s preko 1000 događanja u gotovo 100 hrvatskih gradova i mjesta uz dobru medijsku prezentaciju svih događanja.<br/><br/>

Pozivamo vas, uključite se u događanja 18. Tjedna psihologije u Hrvatskoj što možete učiniti na najrazličitije načine u sredini u kojoj živite i radite - predavanjem, okruglim stolom, radionicom, izložbom i predstavljanjem knjige, plakata, demonstracijom rada psihologa u praksi, tribinom na neku temu koja je aktualna u vašoj zajednici, provođenjem i prezentacijom rezultata vašeg istraživanja, nastupom na radiju, televiziji, pisanim ili elektroničkim medijima, ili nekom sasvim novom aktivnosti za koju pretpostavljate da će pobuditi interes i doprinijeti poboljšanju kvalitete življenja pojedinca i zajednice. Nositelji svih aktivnosti ste upravo vi, kolegice i kolege, psiholozi i psihologinje, članovi stručnih sekcija, županijskih društava psihologa, članovi akademske zajednice, studija psihologije, znanstvenih jedinica i ustanova, studenti psihologije. Pokažimo zajednici u kojoj živimo i radimo što sve znamo i možemo, budimo još vidljiviji u korist svakog hrvatskog psihologa i psihologinje i u korist ukupne zajednice. Svi aktivni sudionici koji prijave i održe svoje aktivnosti dobit će bodove prema Pravilniku o stručnom usavršavanju Hrvatske psihološke komore.<br/><br/>

Sva događanja u 18. Tjednu psihologije besplatna su, kako za izvoditelje aktivnosti tako i za slušače/publiku.<br/>

<br/>Sve vaše prijedloge, ideje i upite šaljite na hpd@psihologija.hr ili se javite na 091 231 2733. 
            </p>
        </div>
    }/>
    </div>
    
    </>
    )
}