import * as React from 'react';

import './style.css';

export default function WeekElement({title, date, hour, minutes, place, address, first_name, goal, institution, type, note}){

    const convertDate = (date)=>{
        let dateSplit = date.split('-')
        return dateSplit[2]+'.'+dateSplit[1]+'.'+dateSplit[0]
    }

    return (
        <div className="week-element-wrap">
        <div className="flex-column week-element">
            <div className="week-element-content">
            <h1 style={{fontSize:"1.5rem", paddingBottom:"0.5rem"}}>{title}</h1>
            <p className="week-datapoint">{address}, {place}</p>
            <p className="week-datapoint">{convertDate(date)}, {hour}:{minutes.toString().length>1?minutes.toString():"0"+minutes.toString()}</p>
            <p className="week-datapoint">izvoditelji/ce: {first_name} </p>
            <p className="week-datapoint">ustanova: {institution}</p>
            <p className="week-datapoint">ciljana skupina: {goal}</p>
            <p className="week-datapoint">vrsta aktivnosti: {type}</p><br/>
            <p className="week-datapoint"> {note}</p>
            </div>
        </div>
        </div>
    )
}