import * as React from 'react';

import './style.css';
import { makeFieldChangeHandle, makeValidateField, randomId, setFieldValue, setSelectDefaultValue } from './util';

export default function HourMinutes({field, formControls, setFormControls, className=undefined, style=undefined}){
    let id = randomId();
    const [validity, setValidity] = React.useState({valid:true, warning:""})


    let handleHourChange = undefined;
    let handleMinuteChange = undefined;
    if(formControls!=undefined){
        const validateField = field.necessary?makeValidateField(field, setValidity, formControls, setFormControls):undefined;
        handleHourChange =  (e) => {
            setFormControls((prevState)=>{
                prevState[field.name].value[0] = e.target.value
                let newField = {value:prevState[field.name].value, validity:prevState[field.name].validity}
            if(field.callback!=undefined){
                field.callback(e);
            }
            if(field.necessary){
                newField.validity = validateField(newField);
            }
            let newState = {}
            newState[field.name] = newField;
            return {
                ...prevState,
                ...newState
            }
            })
        };
        handleMinuteChange =  (e) => {
            setFormControls((prevState)=>{
                prevState[field.name].value[1] = e.target.value
                let newField = {value:prevState[field.name].value, validity:prevState[field.name].validity}
                console.log(newField)
            if(field.callback!=undefined){
                field.callback(e);
            }
            if(field.necessary){
                newField.validity = validateField(newField);
            }
            let newState = {}
            newState[field.name] = newField;
            return {
                ...prevState,
                ...newState
            }
            })
        }
    }

    let hours = [];
    let minutes = [];

    for(let i = 0; i<=23; i+=1){
        hours.push(i)
    }

    for (let i=0;i<60;i+=5){
        minutes.push(i)
    }
    

    return (
        <div className="flex-column">
        <div className="flex-row">
            <div className={"input-field select-field "+(className!=undefined?className:"")} style={field.style}>
                <label htmlFor={id}>sati</label>
                
                <select disabled={field.disabled} value={formControls!=undefined?formControls[field.name].value[0]:field.defaultValue} onChange={handleHourChange!=undefined?handleHourChange:null} className="select" id={id}>
                    <option value="" hidden></option>
                    {
                        hours.map((number, index)=>{
                            return <option key={index} value={number}>{number.toString()}</option>
                        })
                    }
                </select>
                
                </div>
                <div className={"input-field select-field "+(className!=undefined?className:"")} style={field.style}>
                <label htmlFor={id+'a'}>minute</label>
                
                <select disabled={field.disabled} value={formControls!=undefined?formControls[field.name].value[1]:field.defaultValue} onChange={handleMinuteChange!=undefined?handleMinuteChange:null} className="select" id={id+'a'}>
                    <option value="" hidden></option>
                    {
                        minutes.map((number, index)=>{
                            return <option key={index.toString()+'a'} value={number}>{number.toString()}</option>
                        })
                    }
                </select>
                </div>
                
                </div>
                {!formControls[field.name].validity.valid?<div className="warning" style={{textAlign:"left"}}>{formControls[field.name].validity.warning}</div>:<></>}
                </div>
                
               
                
    )
}
