import * as React from 'react';

import { api } from '../../environment';
import {useParams } from 'react-router-dom';
import Form from '../../components/formElements/Form';
import { isEmail, isEmpty, isEmptyHour, isEmptySelect } from '../../components/formElements/validators';
import { FormDataBuilder, makeFormControls, validateForm } from '../../components/formElements/util';
import Modal from '../../components/modal/Modal';
import oblici from '../../assets/oblici';
import zupanije from '../../assets/zupanije_arr';
import gradovi
 from '../../assets/gradovi_arr';
import { isMobile } from 'react-device-detect';



export default function WeekActivityView(){

    const {id} = useParams()

    const [modalHidden, setModalHidden] = React.useState(true);
    const [typeFree, setTypeFree] = React.useState(false)
    const [cities, setCities] = React.useState([])


    let formDataBuilder = new FormDataBuilder();

    const handleCountyChange = (e)=>{
        setCities((prevState)=>{
            return gradovi[e.target.value]
    })
    }

    let newFormData = formDataBuilder
    .addSubtitle("Podatci o prijavitelju")
    .addTextField("first_name", "ime", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("last_name", "prezime", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("phone_contact", "kontakt telefon", true,true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("email", "email adresa", true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
    .addSubtitle("Izvoditelji")
    .addTextField("lector_first_name", "izvoditelji", true, true, [isEmpty], {flex:"1 1 45%"})
    .addSubtitle("Podatci o događanju")
    .addTextField("title", "naslov", true, true, [isEmpty], {flex:"1 1 100%"})
    .addSelectField("type", "oblik događanja", true, oblici, true, [isEmptySelect], {flex:"1 1 100%"}, (e)=>{
        setTypeFree(()=>{
            if(e.target.value==7){
                return true
            } else {
                return false
            }
        })
    })
    .addTextField("type_text", "nazovite oblik aktivnosti", typeFree, typeFree, [isEmpty], {flex:"1 1 100%"}, null, undefined, !typeFree)
    .addDate("date", "datum", false, false, "2025-02-17", "2025-02-23","2025-02-17",[], {flex:"1 1 45%"}, null, undefined, )
    .addHourMinutes("time", "vrijeme", true, true, [isEmptyHour], {flex:"1 1 12%"})
    .addTextField("institution", "institucija", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("goal", "ciljna skupina", true, true, [isEmpty], {flex:"1 1 45%"})
    .addSelectField('county', 'županija', true, zupanije, true, [isEmptySelect], {flex:"1 1 45%"}, handleCountyChange)
    .addSelectField('city', 'grad/općina', true, cities, true, [isEmptySelect], {flex:"1 1 45%"})
    .addTextField('place', 'mjesto', true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("address", "adresa", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextArea("note", "napomena", false, [isEmpty], {flex:"1 1 45%"})
    .addSelectField('status', 'Status prijave', true, [
        {value:'0', text:'Čeka pregled'},
        {value:'1', text:'Na recenziji'},
        {value:'2', text:'Prihvaćena'},
        {value:'3', text:'Prihvaćena uz izmjene'},
        {value:'4', text:'Odbijena'}
    ], true, [isEmptySelect], {flex:'1 1 100%'}).get()

    const [formControls, setFormControls] = React.useState(makeFormControls(newFormData));

    const getData = () => {
        let call = fetch(api+"/get_activity/"+id,{
            method: "POST",
            body:JSON.stringify({
                token:localStorage.getItem('site')
            })
            })
            try{
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    React.useEffect(()=>{
        getData().then((data)=>{
            let formValues = structuredClone(formControls);
            data['time'] = [data['hour'], data['minutes']];
            data['county'] = data['county'].toString();
            data['city'] = data['city'].toString();
            for(let key in data){
                console.log(key);
                if(key!='id' && key!='hour'&&key!='minutes'){
                    formValues[key].value=data[key]
                }
                if(key=='county'){
                    handleCountyChange({target:{value:data['county']}})
                }
            }
            setFormControls(formValues);
        })
        
    }, [])

    const extractData = (formControls)=>{
        let data = {}
        for(let c in formControls){
            data[c] = formControls[c].value
        }
        data['applicant'] = {
            first_name: formControls['first_name'].value,
            last_name: formControls['last_name'].value,
            email: formControls['email'].value,
            phone_contact: formControls['phone_contact'].value
        }
        data['hour'] = data['time'][0];
        data['minutes'] = data['time'][1];
        data['type'] = Number(data['type']);
        return data
    }

    
            

    const handleStatusChange = () => {
        let validity = validateForm(newFormData, formControls, setFormControls);
        if(validity){
        let activity_data = extractData(formControls)
        activity_data = {...activity_data, ...extractData(formControls)}
        console.log(activity_data)
         fetch(api+"/update_activity_week/"+id.toString(),{
            method: "POST",
            body:JSON.stringify(
                {activity:activity_data})
            }).then(
            (response)=>{
                response.json().then((data)=>{
                    if(data['status']===1){
                        setModalHidden(false);
                    }
                })
            }
        )
    }

    }

    return (
        <>
        <h1 style={{marginBottom:"2rem"}}>Pregled događanja</h1>
        <div className={isMobile?"flex-column":"flex-row"} style={{gap: "1.5rem", flexWrap: "wrap"}}>
        <Form formData={newFormData} formControls={formControls} setFormControls={setFormControls}/> </div>
        <Modal hidden={modalHidden} title="Podatci uspješno promijenjeni!" content={<p>Kliknite na "U redu" za povratak na pregled prijave</p>} buttons={[<button className="modal-button" onClick={()=>{setModalHidden(true);window.location.reload()}}>U redu</button>]}/>
        <div className="flex-row">
                <button className="modal-button" style={{margin:"0.5rem"}} onClick={()=>{handleStatusChange()}}>Promijeni status</button>
            </div>
        </>
    )
}