import * as React from 'react';

import './style.css';
import WeekElement from './WeekElement';
import oblici from '../../assets/oblici';
import { isMobile } from 'react-device-detect';
export default function WeekList({data}){

    return (
            
        <div className="flex-column" style={{height:"100%"}}>
            {data!=null?
            (data.length==0?<div style={{fontSize:"3rem"}} className="week-list week-list-centered">U odabranoj županiji nema prijavljenih događanja</div>:
            <div className="week-list">
                {data.map((activity, index)=>{
                    return <WeekElement key={index}
                    title={activity['title']} 
                    date={activity['date']} 
                    hour={activity['hour']}
                    minutes={activity['minutes']}
                    place={activity['place']}
                    address={activity['address']}
                    first_name={activity['lector_first_name']}
                    goal={activity['goal']}
                    institution={activity['institution']}
                    type={oblici[activity['type']-1].text}
                    note={activity['note']}/>
                })
            }

            </div>)
            :<div style={{fontSize:"2.5rem"}} className="week-list week-list-centered">Odaberite županiju</div>}
        </div>
    )
}