import * as React from 'react';

import './style.css';
import { makeFieldChangeHandle, makeValidateField, randomId } from './util';

export default function Date({field, formControls=undefined, setFormControls=undefined, className=undefined, style=undefined, disabled=false}){
    let id = randomId();

    const [validity, setValidity] = React.useState({valid:true, warning:""})

    let handleFieldChange = undefined;
    if(formControls!=undefined){
        const validateField = (field.necessary?makeValidateField(field, setValidity):undefined);
        handleFieldChange = makeFieldChangeHandle(field, setFormControls, validateField)
    }
  
    return (
            <div hidden={field.hidden} className={"input-field "+(className!=undefined?className:"")} style={style}>
                <label htmlFor={id}>{field.label}</label>
                <input className="date" type="date" min={field.min} max={field.max} disabled={field.disabled}  value={formControls!=undefined?formControls[field.name].value:""} onChange={handleFieldChange!=undefined?handleFieldChange:null} id={id}/>
                    {!formControls[field.name].validity.valid?<div className="warning">{formControls[field.name].validity.warning}</div>:<></>}
                </div>
    )
}
