import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';

import '../../assets/style.css';
import './style.css';
import './style_tooltip.css';
import logo from "../../assets/logo/logo.svg";
import BasicDropdown from '../dropdown/BasicDropdown';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useAuth } from '../AuthProvider';
import HubIcon from '@mui/icons-material/Hub';

export default function CrsNavbar(){
    const auth= useAuth();
    const elements = [
        {
            link: '/crs/pregled-prijava-sudjelovanja',
            key: 'pregled-prijava-sudjelovanja',
            icon: <GroupsIcon className="icon"/>,
            tooltip:"Prijave sudjelovanja"
        },
        {
            link: '/crs/pregled-sazetaka',
            key: 'pregled-sazetaka',
            icon: <LibraryBooksIcon className="icon"/>,
            tooltip:"Sažetci"
        },
        {
            link: '/crs/tjedan-pregled',
            key: 'tjedan-pregled',
            icon: <HubIcon className="icon"/>,
            tooltip:"Sažetci"
        },
    ]

    return (
        <div id="nav-vert" className="nav-crs">
            <div id="nav-list"  className="flex-column" style={{height:"100%",justifyContent:"space-between", paddingBottom:"1rem"}}>
                <div className="flex-column">
                {elements.map(({icon, link, key, tooltip}, index)=>{
                    return <NavLink key={key} to={link}><button title={tooltip} className="nav-button">{icon}</button></NavLink>
                })}
                </div>
                <button title="Odjava" onClick={()=>{
                    auth.logout();
                }} className="nav-button"><PowerSettingsNewIcon className="icon"/></button>
            </div>
        </div>
    )
}