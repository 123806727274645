import * as React from 'react';
import LoginForm from '../../components/forms/LoginForm';
import { isEmail, isEmpty } from '../../components/formElements/validators';
import { makeFormControls } from '../../components/formElements/util';
import { useNavigate } from 'react-router-dom';import { useAuth } from '../../components/AuthProvider';
import Dashboard from '../Crs/Dashboard';

export default function Login(){
    const navigate = useNavigate();
    const auth= useAuth();

    const loginFormData=[
        {name:"email", type:'text', label:'E-Mail', necessary:true, formNecessary:true, validators:[isEmpty, isEmail]},
        {name:"password", type:'password', label:'Lozinka', necessary:true, formNecessary:true, validators:[isEmpty]}
    ]

    const [loginControls, setLoginControls] = React.useState(makeFormControls(loginFormData));
    const [warning, setWarning] = React.useState(null)

    return(
        <div style={{height:"100vh", width:"100vw", backgroundColor:"#fefefe"}}>
        <div id="login-box" className="flex-column" style={{width:"25vw", justifyContent:'center', alignItems:"center", gap:"1rem"}}>
            <LoginForm formData={loginFormData} formControls={loginControls} setFormControls={setLoginControls}/>
            {warning!=null?<p style={{color:"red"}}>{warning}</p>:<></>}
            <button onClick={()=>{
                auth.login(loginControls);
            }} 
            className="modal-button button">Prijava</button>
           </div>
           </div>
        
    )
}