import * as React from 'react';
import InputText from './InputText';
import Select from './Select';
import TextArea from './TextArea';
import Tabular from './Tabular';
import Date from './Date';
import HourMinutes from './HourMinutes';

export default function Form({formData, formControls, setFormControls}){

    return(
        formData.map((field, index)=>{
            return field.type==="text"?
            <div key={index} style={field.layoutStyle} ><InputText field={field} formControls={formControls} setFormControls={setFormControls} className={field.className}/></div>:
            field.type==="password"?
            <div key={index}  style={field.layoutStyle} ><InputText password={true} field={field} formControls={formControls} setFormControls={setFormControls} className={field.className}/></div>:
            field.type==="select"?
            <div key={index} style={field.layoutStyle}><Select  field={field} formControls={formControls} setFormControls={setFormControls} className={field.className}/></div>:
            field.type==="textarea"?
            <div key={index} style={field.layoutStyle}><TextArea key={index} field={field} formControls={formControls} setFormControls={setFormControls}/></div>:
            field.type==="tabular"?
            <div key={index} style={field.layoutStyle}><Tabular field={field} formControls={formControls} setFormControls={setFormControls}/></div>:
            field.type==="subtitle"?
            <div key={index} style={field.layoutStyle}><h3 style={{margin:0}} className='form-subtitle' mode={field.mode!==undefined?field.mode:"add"}>{field.text}</h3></div>:
            field.type==="date"?
            <div key={index} style={field.layoutStyle}><Date field={field} formControls={formControls} setFormControls={setFormControls} /></div>:
            field.type==="hourMinutes"?
            <div key={index} style={field.layoutStyle}><HourMinutes field={field} formControls={formControls} setFormControls={setFormControls} className={field.className}/></div>:<p>Nonexistant field</p>
        })
    )
}