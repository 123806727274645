import * as React from 'react';
import {Outlet} from 'react-router-dom';

import '../../assets/style.css';
import Navbar from '../../components/nav/Navbar';
import Footer from '../../components/footer/Footer';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import NoMobile from './NoMobile';

export default function PublicLayout(){
    return (
        <>
        <div className="blue-paper">
        <main>
            <Outlet/>
        </main>
        </div>
        </>
    )
}