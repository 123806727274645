import * as React from 'react';

import '../../assets/style.css';
import '../../assets/style_home.css';
import Section from '../../components/section/Section';
import Navbar from '../../components/nav/Navbar';
import ActivityApplicationForm from '../../components/forms/ActivityApplicationForm';
import { FormDataBuilder, makeFormControls } from '../../components/formElements/util'
import { isEmail, isEmpty, isEmptyHour, isTable } from '../../components/formElements/validators';

import gradovi from '../../assets/gradovi_arr';
import zupanije from '../../assets/zupanije_arr';
import oblici from '../../assets/oblici';

export default function EventApplication(){
    
    let formDataBuilder = new FormDataBuilder();

    const [cities, setCities] = React.useState([])
    const [typeFree, setTypeFree] = React.useState(false)
    const [formControls, setFormControls] = React.useState(null);
    

    const handleCountyChange = (e)=>{
        setCities(gradovi[e.target.value])
    }


    let newFormData = formDataBuilder
    .addSubtitle("Podatci o prijavitelju")
    .addTextField("first_name", "ime", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("last_name", "prezime", true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("phone_contact", "kontakt telefon", true,true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("email", "email adresa", true, true, [isEmpty, isEmail], {flex:"1 1 45%"})
    .addSubtitle("Izvoditelji")
    .addTextField("lector_first_name", "(ime prezime, ime prezime...)", true, true, [isEmpty], {flex:"1 1 45%"})
    .addSubtitle("Podatci o događanju")
    .addTextField("title", "naslov", true, true, [isEmpty], {flex:"1 1 100%"})
    .addSelectField("type", "oblik događanja", true, oblici, true, [isEmpty], {flex:"1 1 100%"}, (e)=>{
        setTypeFree(()=>{
            if(e.target.value==7){
                return true
            } else {
                return false
            }
        })
    })
    .addTextField("type_text", "nazovite oblik aktivnosti", typeFree, typeFree, [isEmpty], {flex:"1 1 100%"}, null, undefined, !typeFree)
    .addDate("date", "datum", false, false, "2025-01-01", "2025-02-28","2025-02-17",[], {flex:"1 1 45%"}, null, undefined, )
    .addHourMinutes("time", "vrijeme", true, true, [isEmptyHour], {flex:"1 1 12%"})
    .addTextField("institution", "institucija",  true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("goal", "ciljna skupina", true, true, [isEmpty], {flex:"1 1 45%"})
    .addSelectField('county', 'županija', true, zupanije, true, [isEmpty], {flex:"1 1 45%"}, handleCountyChange)
    .addSelectField('city', 'grad/općina', true, cities, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField('place', 'mjesto', true, true, [isEmpty], {flex:"1 1 45%"})
    .addTextField("address", "adresa", true, true, [isEmpty], {flex:"1 1 100%"})
    .addTextArea("note", "napomena za polaznike", false, [], {flex:"1 1 100%"}).get()

    const [formData, setFormData] = React.useState(newFormData);
    return (
        <>
        <Navbar elements={[
        {
            text: 'Naslovna',
            link: '/',
            key: 'naslovna'
        },
        /*{
            text: 'Novosti',
            link: '/novosti',
            key: 'novosti'
        },*/
        {
            text: 'Prijava događanja',
            link: '/prijava',
            key: 'prijava'
        },
        {
            text: 'Pregled događanja',
            link: '/pregled',
            key: 'pregled'
        },


    ]}/>
    <div className="content">
    <Section title={<div style={{textAlign:"right"}} className="section-title-font">Prijava događanja</div>}
    content={<div style={{width: "80%", textAlign:"center", margin:"auto"}}> <ActivityApplicationForm formData={newFormData} setFormData={setFormData} formControls={formControls} setFormControls={setFormControls} typeFree={typeFree}/></div>}/>

    </div>
    
    </>
    )
}