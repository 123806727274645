import * as React from 'react';
import { FormDataBuilder, makeFormControls, validateForm } from '../formElements/util';
import { isEmail, isEmpty, isEmptyHour } from '../formElements/validators';
import Form from '../formElements/Form';
import Modal from '../modal/Modal';
import { api } from '../../environment';

import {isBrowser, isMobile } from 'react-device-detect';


import gradovi from '../../assets/gradovi_arr';
import zupanije from '../../assets/zupanije_arr';
import oblici from '../../assets/oblici';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

export default function ActivityApplicationForm({formData, setFormData, formControls, setFormControls, cities, setCities, typeFree, setTypeFree}){


    React.useEffect(()=>{
        setFormControls((prevState)=>{
            let newFormControls = makeFormControls(formData)
            return {...newFormControls, ...prevState}
        })
    }, [typeFree])
    
    const [modalHidden, setModalHidden] = React.useState(true); 
    
    const extractData = (formControls)=>{
        let data = {}
        for(let c in formControls){
            data[c] = formControls[c].value
        }
        data['applicant'] = {
            first_name: formControls['first_name'].value,
            last_name: formControls['last_name'].value,
            email: formControls['email'].value,
            phone_contact: formControls['phone_contact'].value
        }
        data['hour'] = data['time'][0];
        data['minutes'] = data['time'][1];
        data['type'] = Number(data['type']);
        return data
    }

    const sendFormData = () => {
        let activity_data = extractData(formControls)
        activity_data = {...activity_data, ...extractData(formControls)}
        let call = fetch(api+"/submit_activity",{
            method: "POST",
            body:JSON.stringify(
                {activity:activity_data})
            })
            try{
        return call.then(response=>response.json())
            } catch(err){
                console.log(err);
                return {}
            }
    }

    const submitForm = () =>{
        let validity = validateForm(formData, formControls, setFormControls);
        
        if(validity){
            setModalHidden(false);
            sendFormData().then((response)=>{
                console.log(response)
            })
        }

    }

    return (
        <>
        {
            formData != null && formControls!=null?
            <div>
            <div className={isMobile?"flex-column":"flex-row"} style={{gap: "1.5rem", flexWrap: "wrap"}}>
            <Form formControls={formControls} formData={formData} setFormControls={setFormControls}/></div></div>:<></>
            
        }
        <div className="tool-tray"><button onClick={submitForm} className='pillbox'>Prijava</button></div>
        <Modal hidden={modalHidden} title="Vaša prijava je zaprimljena" content={<div>Uspješno ste prijavili događanje. Nakon prolaska postupka recenzije isto će biti vidljivo unutar pregleda događanja.</div>} 
            buttons={[<button className="modal-button" onClick={()=>{
                setModalHidden(true);
                window.location.reload();
            }}>U redu</button>]}/>
        
        </>
    )
}