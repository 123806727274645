import * as React from 'react';

import './style.css';
import {isBrowser, isMobile } from 'react-device-detect';
export default function Table({header, dataSource, filters=null, layout="auto", overflowX="visible", alignHeader="center"}){
    console.log("HEADER", header);
    return(
        <>
        <table className="table" style={{width:"100%", tableLayout:layout, overflowX:overflowX}}>
            <thead>
                <tr className="header">
                    {
                        header.map((cell, index)=>{
                            return <th key={index} style={{textAlign:alignHeader, width:cell.width, position:cell.position}} className="cell">{cell.tableLabel!=undefined?cell.tableLabel:cell.label}</th>
                        })
                    }
                    </tr></thead>
            <tbody >
                {
                    dataSource!=[]?dataSource.map((row, index)=>{
                        return <tr key={index} className="row xtreme">
                            {
                                header.map((cell, index)=>{
                                    return <td key={index} className="cell" style={{width:cell.width, position:cell.position, textAlign:alignHeader, overflowX:cell.overflowX}}>{cell.show!=undefined?cell.show(row):row[cell.name]}</td>
                                })
                            }

                        </tr> 
                    }):<></>
                }
                </tbody>
                </table>
                {filters!=null?
                <div className="flex-row filter-row" style={{gap:"1rem"}}>{filters.map((filter, index)=>{
                    return <div key={index} className="filter-wrap">{filter}</div>
                })}</div>:<></>}
                </>
    )
}