import * as React from 'react';

import './style.css';
import { useAuth } from '../../components/AuthProvider';
import CrsNavbar from '../../components/nav/CrsNavbar';

export default function Dashboard(){
    let auth = useAuth();
    return (
            <div style={{lineHeight:"1.4rem", width:"40vw"}}>
                <h1 style={{marginBottom:"1rem"}}>Dobrodošli.</h1>
                
            </div>
    )
}